// Public: JSON.parse, but with a nicer error message.
function parseJSON (jsonString: string) {
  try {
    return JSON.parse(jsonString)
  }
  catch (error: any) {
    error.message += `.\n${jsonString} is not valid JSON.`
    throw error
  }
}

function get (key: string) {
  try {
    return sessionStorage.getItem(key)
  }
  catch {
    return null
  }
}

function set (key: string, value: any) {
  try {
    sessionStorage.setItem(key, value)
  }
  catch {}
}

function remove (key: string) {
  try {
    sessionStorage.removeItem(key)
  }
  catch {}
}

function getJSON (key: string) {
  const jsonState = get(key)
  return jsonState && parseJSON(jsonState)
}

function setJSON (key: string, value: any) {
  if (value)
    set(key, JSON.stringify(value))
  else
    remove(key)
}

function getAndDelete (key: string) {
  const value = getJSON(key)
  remove(key)
  return value
}

export default { getAndDelete, set: setJSON, get: getJSON, remove }
