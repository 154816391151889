<script lang="ts">
  import Screen from '~/components/Screen.svelte'
  import Cookies from '~/components/Cookies.svelte'
  import { DialogManager, LoadingIndicator } from '~/components'
  import { onRoute, type ResolvedRoute } from '~/utils/router'
  import screensByName from '~/screens'
  import { title, description, image, keywords } from '~/utils/seo'
  import { notifyError } from '~/services/errors'

  let currentUrl = location.href
  let screens: ResolvedRoute[] = []

  $: currentScreen = screens[screens.length - 1]

  let previousScreenPath: string | undefined = undefined

  const resolvedComponents = Object.create(null)

  function resolveComponent ({ path, component }: ResolvedRoute) {
    try {
      return resolvedComponents[component] ||= screensByName[component]()
    }
    catch {
      notifyError({ errorClass: 'UnknownScreen', errorMessage: `Unknown screen ${component} for ${path}` })
    }
  }

  onRoute((stack) => {
    previousScreenPath = currentScreen?.path
    screens = stack.slice(-3)
    currentUrl = location.href
    title.reset()
    description.reset()
    image.reset()
  })

  let duringTransition: 'out' | 'in' | false = false
</script>

<svelte:head>
  <title>{$title}</title>
  <link rel="canonical" href={currentUrl}/>
  <meta name="description" content={$description}/>
  <meta name="keywords" content={$keywords}>
  <meta property="og:title" content={$title}/>
  <meta property="og:description" content={$description}/>
  <meta property="og:type" content="website"/>
  <meta property="og:image" content={$image}/>
  <meta property="og:image:width" content="1200"/>
  <meta property="og:image:height" content="627"/>
  <meta property="og:url" content={currentUrl}/>
  <meta property="twitter:title" content={$title}/>
  <meta property="twitter:description" content={$description}/>
  <meta property="twitter:image" content={$image}/>
  <meta property="twitter:card" content="summary"/>
  <meta property="twitter:url" content={currentUrl}/>
</svelte:head>

{#each screens as screen (screen.key)}
  <Screen
    transition={screen.transition}
    visible={screen === currentScreen || (duringTransition === 'in' && screen.path === previousScreenPath)}
    current={screen === currentScreen}
    on:pagetransition={e => duringTransition = e.detail}
  >
    {#await resolveComponent(screen)}
      <div class="grid place-center">
        <LoadingIndicator/>
      </div>
    {:then component}
      <svelte:component this={component} {...screen.props}/>
    {/await}
  </Screen>
{/each}

<Cookies/>

<DialogManager/>

<style>
  :global(h6) {
    --at-apply: text-altground mb-3 self-start uppercase text-2xs tracking-wider;
  }
</style>
