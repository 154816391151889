export default function matchPath (path: string, exact = true) {
  if (!path)
    return { match: true, url: location.pathname, props: {} as Record<string, string> }

  const keys = []
  let pattern = ''

  let segment
  const segments = path.split('/')
  if (!segments[0]) segments.shift()

  /* eslint-disable no-cond-assign */
  while ((segment = segments.shift())) {
    if (segment[0] === '*') {
      keys.push('wild')
      pattern += '/(.*)'
    }
    else if (segment[0] === ':') {
      const o = segment.indexOf('?', 1)
      const ext = segment.indexOf('.', 1)
      keys.push(segment.substring(1, ~o ? o : ~ext ? ext : segment.length))
      pattern += !!~o && !~ext ? '(?:/([^/]+?))?' : '/([^/]+?)'
      if (~ext) pattern += `${~o ? '?' : ''}\\${segment.substring(ext)}`
    }
    else {
      pattern += `/${segment}`
    }
  }
  /* eslint-enable no-cond-assign */

  const regex = new RegExp(`^${pattern}${!exact ? '(?=$|/)' : '/?$'}`, 'i')
  const captures = regex.exec(location.pathname)
  if (!captures) return { match: false, props: {} as Record<string, string> }

  const res = { match: true, path, url: captures[0], props: {} as Record<string, string> }
  for (let i = 0; i < keys.length; i += 1) res.props[keys[i]] = decodeURI(captures[i + 1])

  return res
}
