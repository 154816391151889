import { getFunctions, httpsCallable } from 'firebase/functions'
import { app } from '~/services/firebase'

const functions = getFunctions(app)

export function callFunction<FnResult, FnParams = any> (
  name: string,
  fnParams: FnParams,
): Promise<FnResult> {
  return httpsCallable<FnParams, FnResult>(functions, name)(fnParams)
    .then(response => response.data)
}
