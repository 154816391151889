import { resetableStore } from '~/utils/store'

import { baseUrl } from '~/utils/router'
import bannerSrc from '~/images/social-banner.png'

export const title = resetableStore('Artist Power Center')
export const description = resetableStore(
  'The Artist Power Center is a new digital web app designed to support artists in finding work, securing resources, promoting their art, and connecting with other artists and sponsors in one centralized place. The platform will specifically provide opportunities for Black, Indigenous, People of Color, and LGBTQIA+ artists in the Bay Area, aiming to recognize and strengthen the power of artists as this is essential to the health and well-being of our communities.',
)
export const image = resetableStore(baseUrl + bannerSrc)
export const keywords = resetableStore(
  'Artist Power Center, Bay Area Artists, Job Opportunities, Grants, Workshops, LGBTQIA+ Artists, Disabled Artists, Indigenous Artists, Arts Professionals, Creative Opportunities, Local Arts Organizations',
)
