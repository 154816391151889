import 'uno.css'

import '~/services/firebase'
import '~/services/bugsnag'

import { trackPageView, trackSignIn } from '~/services/analytics'
import { onAuth } from '~/services/auth'
import { defineRoutes, onRoute } from '~/utils/router'
import Stack from '~/components/Stack.svelte'

defineRoutes([
  {
    path: '/',
    component: 'PowerLine',
  },
  {
    path: '/search',
    component: 'PowerLine',
  },
  {
    path: '/explore',
    component: 'PowerLine',
  },
  {
    path: '/explore/grants',
    component: 'PowerLine',
  },
  {
    path: '/explore/:searchString',
    component: 'PowerLine',
  },
  {
    path: '/community',
    component: 'Community',
  },
  {
    path: '/about',
    component: 'About',
  },
  {
    path: '/admin',
    component: 'Admin',
  },
  {
    path: '/admin/drafts',
    component: 'AdminDrafts',
  },
  {
    path: '/admin/featured-artists',
    component: 'AdminFeaturedArtists',
  },
  {
    path: '/admin/jobs',
    component: 'AdminJobs',
  },
  {
    path: '/admin/job/:id',
    component: 'AdminPublishedJob',
  },
  {
    path: '/admin/partners',
    component: 'AdminPartners',
  },
  {
    path: '/admin/partner/:id',
    component: 'PartnerProfile',
  },
  {
    path: '/admin/featured-partners',
    component: 'AdminFeaturedPartners',
  },
  {
    path: '/admin/partner-jobs',
    component: 'AdminPartnerJobs',
  },
  {
    path: '/admin/review-jobs',
    component: 'AdminReviewJobs',
  },
  {
    path: '/admin/review-job/:id',
    component: 'AdminReviewJob',
  },
  {
    path: '/admin/splash',
    component: 'AdminSplash',
  },
  {
    path: '/admin/users',
    component: 'AdminUsers',
  },
  {
    path: '/account/edit/:section?',
    component: 'UserEdit',
  },
  {
    path: '/account/email',
    component: 'UserEmail',
  },
  {
    path: '/account/feature',
    component: 'UserFeature',
  },
  {
    path: '/account/liked-artists',
    component: 'UserLikedArtists',
  },
  {
    path: '/account/:uid/feature',
    component: 'UserFeature',
  },
  {
    path: '/account/intro',
    component: 'UserIntro',
  },
  {
    path: '/account/:uid/intro',
    component: 'UserIntro',
  },
  {
    path: '/account/job-categories',
    component: 'UserJobCategories',
  },
  {
    path: '/account/location',
    component: 'UserLocation',
  },
  {
    path: '/account/name',
    component: 'UserName',
  },
  {
    path: '/account/password',
    component: 'UserPassword',
  },
  {
    path: '/account/practices',
    component: 'UserPractices',
  },
  {
    path: '/account/settings',
    component: 'UserSettings',
  },
  {
    path: '/account/zip',
    component: 'UserZip',
  },
  {
    path: '/account/unsubscribe',
    component: 'Unsubscribe',
  },
  {
    path: '/account',
    component: 'User',
  },
  {
    path: '/artists',
    component: 'Artists',
  },
  {
    path: '/artists/:artistId',
    component: 'User',
  },
  {
    path: '/artists/:id/give-feedback',
    component: 'ArtistLeaveFeedback',
  },
  {
    path: '/artists/:id/give-shoutout',
    component: 'ArtistLeaveFeedback',
  },
  {
    path: '/account/feedback',
    component: 'UserFeedback',
  },
  {
    path: '/artists/:id/shoutouts',
    component: 'UserFeedback',
  },
  {
    path: '/featured-artist/:id/endorse',
    component: 'EndorseArtist',
  },
  {
    path: '/featured-artists/',
    component: 'ListFeaturedArtists',
  },
  {
    path: '/featured-partners/',
    component: 'ListFeaturedPartners',
  },
  {
    path: '/featured-artist/:id',
    component: 'FeaturedArtist',
  },
  {
    path: '/featured-partner/:id',
    component: 'FeaturedPartner',
  },
  {
    path: '/help',
    component: 'Help',
  },
  {
    path: '/opportunity/:id/:slug?',
    component: 'Opportunity',
  },
  {
    path: '/partners',
    component: 'PartnersLanding',
  },
  {
    path: '/partners/directory',
    component: 'Partners',
  },
  {
    path: '/partner/login',
    component: 'PartnerLogin',
  },
  {
    path: '/partner/signup',
    component: 'PartnerSignup',
  },
  {
    path: '/partner/create',
    component: 'PartnerCreate',
  },
  {
    path: '/partner/workspaces',
    component: 'PartnerWorkspaces',
  },
  {
    path: '/partner/:id/team',
    component: 'PartnerTeam',
  },
  {
    path: '/partner/:id/jobs',
    component: 'PartnerJobs',
  },
  {
    path: '/partner/:id/profile',
    component: 'PartnerProfile',
  },
  {
    path: '/partner/:partnerId',
    component: 'Partner',
  },
  {
    path: '/partner',
    component: 'PartnerWelcome',
  },
  {
    path: '/referrals',
    component: 'Referrals',
  },
  {
    path: '/for-you',
    component: 'ForYou',
  },
  {
    path: '/login',
    component: 'Login',
  },
  {
    path: '/logout',
    component: 'Logout',
  },
  {
    path: '/reset-password',
    component: 'ResetPassword',
  },
  {
    path: '/unsubscribe',
    component: 'Unsubscribe',
  },
  {
    path: '/saved',
    component: 'Saved',
  },
  {
    path: '/signup/account',
    component: 'SignupAccount',
  },
  {
    path: '/signup/job-categories',
    component: 'SignupJobCategories',
  },
  {
    path: '/signup/location',
    component: 'SignupLocation',
  },
  {
    path: '/signup/name',
    component: 'SignupName',
  },
  {
    path: '/invite/:id',
    component: 'SignupName',
  },
  {
    path: '/signup/practices',
    component: 'SignupPractices',
  },
  {
    path: '/signup/zip',
    component: 'SignupZip',
  },
  {
    path: '/signup',
    component: 'Signup',
  },
  {
    path: '/welcome',
    component: 'Welcome',
  },
  {
    path: '/404',
    component: 'NotFound',
  },
  {
    path: '/*',
    component: 'NotFound',
  },
])

// Whenever the router fires,
// track the current url.

onRoute(() => {
  trackPageView(location.pathname)
})

trackSignIn()

// Create a Stack and attach it to the document body when firebase is ready.
const unsubscribe = onAuth(() => {
  window._APP_ ||= new Stack({ target: document.body })
  unsubscribe()
})
