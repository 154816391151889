export { compact } from 'lodash-es'

// Internal: Allows to use most of the helpers with a predicate Function (like
// in lodash) or with an element that must be an exact match.
function toPredicate(predicate: any) {
  return typeof predicate === 'function' ? predicate : (item: any) => item === predicate
}

// Public: Removes an element from the Array using 'splice`, which Vue's
// reactive system can detect.
//
// Returns the removed item, or undefined.
export function removeBy<T>(array: T[], itemOrPredicate: T | ((item: T) => boolean)): T | undefined {
  const index = array.findIndex(toPredicate(itemOrPredicate))
  if (index !== -1) return array.splice(index, 1)[0]
}

// Public: Equivalent to Array.wrap in Ruby, wraps a single object.
export function wrapArray<T>(val: T | T[]): T[] {
  return Array.isArray(val) ? val : [val]
}
