import { writable, type Writable } from 'svelte/store'

export interface Resetable<T> extends Writable<T> {
  reset: () => void
}

export function resetableStore<T> (defaultValue: T) {
  const store = writable(defaultValue) as Resetable<T>
  store.reset = () => store.set(defaultValue)
  return store
}

export function trimmable (value?: string | null) {
  const store = writable(value || '')

  return {
    ...store,
    set: (value: string) => {
      store.set((value || '').trim())
    },
  }
}
