import Bugsnag from '@bugsnag/js'
import { currentUser } from '~/services/auth'

if (import.meta.env.MODE !== 'development') {
  Bugsnag.start({
    apiKey: import.meta.env.BUGSNAG_API_KEY,
    appVersion: import.meta.env.VERSION_NUMBER,
    releaseStage: import.meta.env.MODE,
    enabledReleaseStages: ['staging', 'production'],
    onSession (session) {
      const user = currentUser()
      if (user)
        session.setUser(user.id, user.email || undefined, user.displayName || undefined)
    },
  })
}

export default Bugsnag
