import { onMount } from 'svelte'
import { currentParams } from '~/services/search'
import { currentUser } from '~/services/auth'
import firebase from '~/services/firebase'
import cache from '~/services/cache'
import { notifyError } from '~/services/errors'
import type { User } from '~/types'
export type { User }
export { nameFor, firstNameFor } from '~/format/user'

export async function userDoc (id?: string) {
  return await firebase.firestore().doc(`users/${id}`).get()
}

export function toUser (doc: Awaited<ReturnType<typeof userDoc>>) {
  const data = doc.data()
  return data && { ...doc.data() as User, id: doc.id }
}

export async function findUser (id?: string): Promise<User | undefined> {
  if (!id) return {} as User
  return userDoc(id).then(toUser)
}

export async function getUserData (user = currentUser()): Promise<User | undefined> {
  return await findUser(user?.id)
}

function onMissingUser (err: any) {
  if (err) notifyError(err)
}

interface UserDataOptions {
  userId?: string
  onData?: (user: User) => void
  onError?: (err?: any) => void
}

export function withUserData (callback: (user: User) => void): User | undefined
export function withUserData (options: UserDataOptions): User | undefined

export function withUserData (options: ((user: User) => void) | UserDataOptions) {
  if (typeof options === 'function')
    options = { onData: options }

  const { onData, onError = onMissingUser, userId = currentParams().get('artistId') } = options
  const cacheKey = userId ? `user/${userId}` : 'user'

  onMount(async () => {
    const uid = userId ?? currentUser()?.id
    if (!uid) return onError(null)
    firebase.firestore().doc(`users/${uid}`)
      .onSnapshot((doc) => {
        const data = doc.data() as User
        if (data) {
          const withId = { ...data, id: uid, uid }
          cache.set(cacheKey, withId)
          onData?.(withId)
        }
        else {
          onError(null)
        }
      }, onError)
  })
  return cache.get(cacheKey)
}

export async function updateUserData (data: Partial<User>, user?: { id: string }) {
  const id = user ? user.id : currentUser()?.id
  if (id)
    return firebase.firestore().doc(`users/${id}`).update(data)
}

export async function setUserData (data: Partial<User>) {
  return firebase.firestore().collection('users').doc(data.id).set(data)
}
