import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import { app } from '~/services/firebase'
import { currentUser, afterSignIn } from '~/services/auth'

export type PartnerEvent = 'partner_visited' | 'partner_search' | 'featured_partner_visit' | 'featured_partner_shared'

export type ArtistEvent =
  | 'artist_visited'
  | 'artist_search'
  | 'featured_artist_visit'
  | 'featured_artist_shared'
  | 'featured_artist_endorsed'
  | 'superconnector_feedback'
  | 'superconnector_shoutout'
  | 'superconnector_referral_link'

export type JobEvent =
  | 'apply_to_job'
  | 'apply_to_job_not_signed_in'
  | 'job_added'
  | 'job_copied'
  | 'job_saved'
  | 'job_search'
  | 'job_shared'
  | 'job_unsaved'
  | 'job_updated'
  | 'job_reminder'
  | 'job_reminder_unset'
  | 'job_view'
  | 'just_for_you_click'
  | 'partner_post'

export type UserEvent =
  | 'user_bio_added'
  | 'user_feature_added'
  | 'user_link_added'
  | 'user_video_added'
  | 'user_signin'
  | 'login'

type Event =
  | PartnerEvent
  | ArtistEvent
  | UserEvent
  | JobEvent
  | 'enable_notifications'
  | 'disable_notifications'
  | 'geocoding_not_found'
  | 'geocoding_error'
  | 'signup'
  | 'signup_job_category'
  | 'signup_practice'
  | 'page_view'
  | 'referral_click'

const analytics = import.meta.env.MODE !== 'development' && getAnalytics(app)

export function trackPageView(path: string) {
  if (!analytics) return

  trackEvent('page_view', { url: path })
}

export function trackEvent(eventType: Event, data?: Record<string, any>) {
  if (import.meta.env.MODE === 'development') console.info(eventType, data)

  if (!analytics) return

  const user = currentUser()
  const userData = user ? { user_email: user.email } : {}

  if (import.meta.env.MODE !== 'development') logEvent(analytics, eventType as any, { ...userData, ...data })
}

export async function trackSignIn() {
  if (!analytics) return

  const firebaseUser = currentUser()
  if (firebaseUser) {
    const user = await afterSignIn(firebaseUser)
    const role = user.canAdminJobs ? 'Admin' : user.canOwnJobs ? 'Partner' : 'Artist'

    setUserId(analytics, firebaseUser.uid, { global: true })
    setUserProperties(analytics, { role }, { global: true })
  }
}
