/*

A router that emits its whole history as an array on each
route change.

*/

import { writable } from 'svelte/store'
import { isString } from 'lodash-es'
import screensByName from '~/screens'
import matchPath from '~/utils/match'

export interface Route {
  path: string
  component: string
}

export interface NavigationOptions {
  push?: boolean
  replace?: boolean
  transition?: string
}

export interface ResolvedRoute extends Route, NavigationOptions {
  key: string
  props: Record<string, any>
}

let routes: Route[] = []
let stack: ResolvedRoute[] = []

export type OnRoute = (routes: ResolvedRoute[]) => void

const subscribers: OnRoute[] = []

export const baseUrl = location.origin

export const currentPath = writable(location.pathname)

export function onRoute(callback: OnRoute) {
  subscribers.push(callback)
  callback(stack)
}

function emitChange() {
  currentPath.set(stack[stack.length - 1]?.path || location.pathname)
  subscribers.forEach((sub) => sub(stack))
}

function route(options: NavigationOptions = {}) {
  for (const screen of routes) {
    const { match, props } = matchPath(screen.path)
    if (!match) continue

    const component = screen.component
    const lastScreen = stack[stack.length - 1]
    if (lastScreen?.component === component && !options.push) {
      lastScreen.props = props
    } else {
      stack.push({
        path: location.pathname,
        component,
        props,
        key: location.pathname + Date.now(),
        ...options,
      })
    }
    emitChange()

    if (options.replace && stack.length > 1) {
      setTimeout(() => {
        stack.splice(-2, 1)
        emitChange()
      }, 500)
    }

    break
  }
}

export function defineRoutes(arr: Route[]) {
  routes = arr.map((route) => {
    if (import.meta.env.MODE === 'development' && isString(route.component)) {
      const component = screensByName[route.component]
      if (!component) throw new Error(`Unknown screen '${route.component}'`, screensByName)
    }

    return route
  })
  route()
}

export function reset(url: string, options?: NavigationOptions) {
  stack = []
  if (url) push(url, options)
}

export function push(url: string, options?: NavigationOptions) {
  if (options?.replace) history.replaceState(null, '', url)
  else history.pushState(null, '', url + (location.search || ''))

  route(options)
}

export function replace(url: string, options?: NavigationOptions) {
  push(url, { ...options, replace: true })
}

export function back(defaultPath = '/') {
  if (stack.length === 1) push(defaultPath)
  else history.back()
}

window.addEventListener('popstate', () => {
  stack.pop()
  if (!stack.length) {
    route()
    return
  }
  emitChange()
})

document.body.addEventListener('click', (e) => {
  if (e.shiftKey || e.altKey || e.ctrlKey || e.metaKey) return // skip

  let el = e.target
  do {
    if (el.tagName === 'A' && el.href && !el.target) {
      if (el.href.includes('mailto')) return
      e.preventDefault()
      const url = new URL(el.href)
      push(url.pathname + url.search, el.dataset)
      break
    }
    el = el.parentElement
  } while (el)
})
