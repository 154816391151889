<script lang="ts">
  import { fly } from 'svelte/transition'
  import { onMount } from 'svelte'
  let open = false

  function dismiss () {
    open = false
  }

  function accept () {
    localStorage.setItem('apc-cookie-policy-0821', 'accepted')
    open = false
  }

  onMount(() => {
    const store = localStorage.getItem('apc-cookie-policy-0821')
    if (store !== 'accepted') open = true
  })
</script>

{#if open}
  <div class="container shadow-input" transition:fly={{ y: 40 }}>
    <div class="text">
      <p class="title">Allow us to use cookies?</p>
      <p class="message">
        We use cookies to analyze traffic and offer better services. For more information,
        see our
        <a
          href="https://www.termsfeed.com/live/8a98de9b-a80e-4e13-9321-f3bec5a23d2b"
          alt=""
          target="_blank">Cookie Policy</a
        >.
      </p>
    </div>
    <nav>
      <div class="close" on:click={dismiss}><i class="fal fa-times"/></div>
      <div class="button" on:click={accept}>Accept</div>
    </nav>
  </div>
{/if}

<style>
  .container {
    position: fixed;
    left: 0;
    bottom: 88px;
    right: 0;
    z-index: 200;
    padding: 16px;
    background-color: #f7f6f8;
    display: flex;
    margin: 0 1rem;
    width: calc(100% - 2rem);
  }

  .text {
    flex: 1;
    padding-right: 16px;
  }

  .title {
    font-weight: bold;
    padding: 0 0 4px 0;
    margin: 0;
  }

  .message {
    padding: 0;
    margin: 0;
  }

  .message a {
    color: inherit;
    text-decoration: underline;
  }

  nav {
    text-align: right;
  }

  .close {
    color: #654370;
    font-size: 24px;
    padding: 0 0 12px 2px;
    cursor: pointer;
  }

  .button {
    padding: 12px 24px;
    font-size: bold;
    color: white;
    background-color: #654370;
    border-radius: 24px;
    cursor: pointer;
  }

  @media (min-width: 1200px) {
    .container {
      left: auto;
      right: 16px;
      bottom: 16px;
      width: 400px;
    }
  }
</style>
