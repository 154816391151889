import { Cloudinary } from '@cloudinary/url-gen'

import type { CloudinaryResource } from '~/types'
export type { CloudinaryResource }

export interface CloudinaryOptions {
  angle: number
  cloudinaryId: CloudinaryResource['cloudinaryId']
  /**
   * Uses auto-gravity to focus on the interesting part of the image.
   */
  auto: boolean
  face: boolean
  format: CloudinaryResource['format']
  grayscale: boolean
  height: number
  type: CloudinaryResource['type']
  url: string
  width: number | 'auto'
}

export interface UploadOptions {
  folder: 'media' | 'splash' | 'profile'
  onComplete: Function
  onError: Function
  onProgress: Function
}

const defaultOptions: Partial<CloudinaryOptions> = {
  format: 'png',
}

export const cloudinary = new Cloudinary({ cloud: { cloudName: import.meta.env.CLOUDINARY_CLOUD } })

/**
 * Build a cloudinary URL for ids, with transformations.
 */
export function imageUrl (media?: string | Partial<CloudinaryResource>, options?: Partial<CloudinaryOptions>) {
  return url(media, { format: 'jpg', ...options })
}

export const cloudinaryResourceUrl = `https://res.cloudinary.com/${import.meta.env.CLOUDINARY_CLOUD}`

/**
 * Build a cloudinary URL for ids, with transformations.
 */
export function url (media?: string | Partial<CloudinaryResource>, options?: Partial<CloudinaryOptions>) {
  if (!media)
    return ''

  options = {
    ...defaultOptions,
    ...(typeof media === 'string' ? { cloudinaryId: media } : media),
    ...options,
  }

  const mutations = []
  if (options.width)
    mutations.push(`w_${options.width}`)

  if (options.height)
    mutations.push(`h_${options.height}`)

  if (options.width || options.height)
    mutations.push('c_fill')

  if (options.face)
    mutations.push('g_face')

  if (options.auto)
    mutations.push('g_auto')

  if (options.grayscale) {
    mutations.push('e_grayscale')
    mutations.push('o_30')
  }
  if (options.angle)
    mutations.push(`a_${options.angle}`)

  const ops = mutations.join(',')
  if (options.type === 'audio')
    return '/media/audio.jpg'

  if (options.type === 'link')
    return `${cloudinaryResourceUrl}/image/${options.format}/${ops}/${encodeURI(options.url!)}`

  return [
    cloudinaryResourceUrl,
    options.type === 'video' ? 'video/upload' : 'image/upload',
    ops,
    'q_auto',
    `${options.cloudinaryId}.${options.format}`,
  ].filter(x => x).join('/')
}

export function upload (file: File, folder: UploadOptions['folder'], options: Partial<UploadOptions> = {}): Promise<CloudinaryResource> {
  return new Promise((resolve, reject) => {
    if (!file) {
      const res = {
        status: '404',
        message: 'No file given',
      }
      if (options.onError)
        options.onError(res)

      reject(res)
    }
    const formData = new FormData()

    const uploadPreset = (folder === 'profile' && import.meta.env.CLOUDINARY_PROFILE)
      || (folder === 'splash' && import.meta.env.CLOUDINARY_SPLASH)
      || (folder === 'media' && import.meta.env.CLOUDINARY_MEDIA)

    formData.append('upload_preset', uploadPreset)
    formData.append('file', file)

    if (options.folder)
      formData.append('folder', options.folder)

    const xhr = new XMLHttpRequest()

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState !== 4)
        return

      if (xhr.status !== 200) {
        if (options.onError)
          options.onError(xhr)

        reject(xhr)
        return
      }
      let res = JSON.parse(xhr.responseText)
      if (!res || !res.public_id) {
        if (options.onError)
          options.onError(res)

        reject(res)
        return
      }
      const type = file.type?.split('/')[0]
      res = {
        cloudinaryId: res.public_id,
        type: type || res.resource_type,
        format: res.format,
      }
      if (options.onComplete)
        options.onComplete(res)

      resolve(res)
    }

    const onProgress = options.onProgress
    if (onProgress) {
      xhr.upload.addEventListener('progress', (e) => {
        onProgress({
          loaded: e.loaded,
          total: e.total,
          progress: Math.round((e.loaded * 100.0) / e.total),
        })
      })
    }

    xhr.open('post', `https://api.cloudinary.com/v1_1/${import.meta.env.CLOUDINARY_CLOUD}/auto/upload`)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    xhr.send(formData)
  })
}
