// Import and configure all screen urls.
const screenImports = import.meta.glob('./**/*.svelte', { import: 'default' })

const prefixIndex = './'.length
const suffixIndex = -'.svelte'.length

export default Object.fromEntries(
  Object.entries(screenImports)
    .map(([filename, component]) => [
      filename.slice(prefixIndex, suffixIndex),
      component,
    ]),
)

// NOTE: By preloading all screens, we improve the performance when navigating
// to a new page, and prevent chunks from being unavailable when the app is
// re-deployed.
if ('requestIdleCallback' in window)
  Object.values(screenImports).map(fn => requestIdleCallback(fn))
