<script lang="ts">
  import { type Dialog, dialogs, removeDialog } from '~/stores/dialogs'

  function onDialogClose (dialog: Dialog, event: Event) {
    if (!event.defaultPrevented) removeDialog(dialog)
  }
</script>

<span class="dialog-manager">
  {#each $dialogs as dialog (dialog.id)}
    <svelte:component
      this={dialog.component}
      id={dialog.id}
      {...dialog.attrs}
      on:dialog-close={event => onDialogClose(dialog, event)}
    />
  {/each}
</span>
