export function snapToDoc <T> (doc: { data: () => any; id: any }) {
  const data = doc.data()
  return data ? { id: doc.id, ...data } as T : undefined
}

export function snapToDocs <T> (snap: { docs: any }) {
  const res: T[] = []
  for (const snapDoc of snap.docs) {
    const doc = snapToDoc(snapDoc)
    if (doc)
      res.push(doc as T)
  }
  return res
}

export async function findBy<T> (collection: any, field: any, ids: string[]) {
  if (!ids?.length)
    return []

  const batches: T[][] = []
  const idsToBatch = [...ids]

  while (idsToBatch.length) {
    // firestore has limitation of 10 values for in queries.
    const batch = idsToBatch.splice(0, 10)

    batches.push(
      collection.where(field, 'in', [...batch]).get()
        .then((docs: any) => snapToDocs<T>(docs)),
    )
  }

  return Promise.all(batches).then(content => content.flat())
}
