<script lang="ts">
  export let inline = false
</script>

<div class={`loading-indicator ${$$restProps.class || ''}`} class:inline>
  <i class="far fa-spinner-third fa-spin"/>
</div>

<style lang="scss">
  .loading-indicator {
    padding: 32px 0;
    text-align: center;
    font-size: 24px;
    color: #999999;

    &.inline {
      padding: 0;
    }
  }
</style>
