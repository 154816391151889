import {
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut as logOut,
  updateEmail,
  updatePassword,
  updateProfile,
  type NextOrObserver,
  type User as FirebaseUser,
} from 'firebase/auth'
import { app, snapToDoc } from '~/services/firebase'
import { setUserData, updateUserData, userDoc, type User } from '~/services/user'
import { trackEvent, trackSignIn } from '~/services/analytics'
import type { getData } from '~/stores/onboarding'
import { imageUrl } from '~/utils/cloudinary'

export type { FirebaseUser }

const auth = getAuth(app)

export function currentUser () {
  const user = getAuth(app).currentUser
  return user ? { ...user, id: user.uid } : undefined
}

export async function signIn (email: string, password: string) {
  await signInWithEmailAndPassword(auth, email, password)
  trackEvent('user_signin')
  trackEvent('login')
  trackSignIn()
}

export async function signUp (email: string, password: string, data: ReturnType<typeof getData>) {
  const { user } = await createUserWithEmailAndPassword(auth, email, password)

  const id = user.uid
  const createdAt = Number(new Date(user.metadata?.creationTime || Date.now()))
  const newUser: User = { id, email, createdAt, doNotify: true, ...data }
  await setUserData(newUser)
  trackEvent('signup', { id, email, zip: data.zip })
  return newUser
}

export function resetPassword (email: string) {
  return sendPasswordResetEmail(auth, email)
}

export async function signOut () {
  await logOut(auth)
  sessionStorage.clear()
}

export function onAuth (fn: NextOrObserver<FirebaseUser>) {
  return onAuthStateChanged(auth, fn)
}

export async function updateCurrentPassword (password: string) {
  const user = currentUser()
  if (user) {
    const credential = EmailAuthProvider.credential(user.email!, password)
    await reauthenticateWithCredential(user, credential)
    await updatePassword(user, password)
  }
}

export async function updateUserEmail (email: string) {
  const user = currentUser()
  if (user) {
    await updateEmail(user, email)
    await updateUserData({ email })
  }
}

export async function afterSignIn (firebaseUser: FirebaseUser) {
  const userSnap = await userDoc(firebaseUser.uid)
  const user = snapToDoc<User>(userSnap)!

  updateProfile(firebaseUser, {
    displayName: user.name || user.firstName,
    photoURL: imageUrl(user.profileMedia || { cloudinaryId: user.intro, type: 'video' }),
  })

  const { lastSignInTime, creationTime } = firebaseUser.metadata
  if (user.lastSignInTime !== lastSignInTime)
    userSnap.ref.update({ createdAt: Number(new Date(creationTime!)), lastSignInTime })

  return user
}
