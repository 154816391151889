import algoliasearch from 'algoliasearch/lite'
import type { User } from '~/services/user'
import type { Job, ScrapedJob } from '~/services/jobs'
import type { JobStatus, JobSearchOptions } from '~/types'
import { callFunction } from '~/services/functions'
export type { JobSearchOptions as SearchOptions, JobStatus as Status }

export { ScrapedJob }

// Internal: The query parameters of the current URL.
export function currentParams () {
  return new URLSearchParams(location.search)
}

// Public: Allows to add/update/remove a query parameter in the current URL,
// using the History API.
//
// queryParams - An object where its properties represent query string parameters.
export function replaceQuery (queryParams: Record<string, any>) {
  const params = currentParams()
  const oldParams = params.toString()
  Object.entries(queryParams).forEach(([name, value]) => {
    value === undefined || value === null ? params.delete(name) : params.set(name, value)
  })
  const newParams = params.toString()
  if (newParams !== oldParams)
    history.replaceState(queryParams, '', `?${newParams}`)
}

export async function searchJobs (user: User, { locations, ...searchOptions }: JobSearchOptions) {
  return callFunction<Job[]>('searchJobs', {
    ...searchOptions,
    locations: locations ? labelsToLocations(user, locations) : undefined,
  })
}

export const algolia = algoliasearch(import.meta.env.ALGOLIA_APP, import.meta.env.ALGOLIA_SEARCH_KEY)

export async function searchScrapedJobs (query: string, { limit }: { limit: number }) {
  const index = algolia.initIndex('scraped-jobs')
  const { hits, nbHits: count } = await index.search(query, { offset: 0, length: limit })
  return { count, items: hits.map(result => ({ id: result.objectID, ...result }) as ScrapedJob) }
}

function labelsToLocations (user: User, locations: string[]) {
  return locations.map((location) => {
    if (location === 'Remote') return 'remote'
    if (location === 'San Francisco Bay Area') return '37.7860211,-122.4029726'
    if (location.includes('Near')) return `${user.lat},${user.lng}`
    return '0,0'
  })
}
