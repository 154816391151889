<script lang="ts">
  import { cubicInOut } from 'svelte/easing'
  import { createEventDispatcher } from 'svelte'

  // The transition controls how the screen enters and exits.
  export let transition = 'snap'

  // Whether the page is being displayed.
  export let current: boolean

  // Whether the page should be displayed.
  export let visible: boolean

  const fixed = `
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 50000;
  `

  // Transition function.
  function slide (node: HTMLElement, _: any) {
    const existingTransform = getComputedStyle(node).transform.replace('none', '')
    switch (transition) {
      case 'slide-up':
        return {
          delay: 0,
          duration: 300,
          easing: cubicInOut,
          css: (t: number) => `
            transform: ${existingTransform} translateY(${100 - t * 100}%);
            ${fixed}
          `,
        }

      case 'slide-left':
        return {
          delay: 0,
          duration: 300,
          easing: cubicInOut,
          css: (t: number) => `
            transform: ${existingTransform} translateX(${100 - t * 100}%);
            ${fixed}
          `,
        }
      default:
        return {}
    }
  }

  const dispatch = createEventDispatcher()

  function onIntroStart () {
    dispatch('pagetransition', 'in')
  }

  function onOutroStart () {
    if (current)
      dispatch('pagetransition', 'out')
  }

  function onIntroEnd () {
    dispatch('pagetransition', false)
  }

  function onOutroEnd () {
    if (current)
      dispatch('pagetransition', false)
  }
</script>

<section
  class="screen relative flex-col text-foreground"
  class:visible={visible}
  transition:slide
  on:introstart="{onIntroStart}"
  on:outrostart="{onOutroStart}"
  on:introend="{onIntroEnd}"
  on:outroend="{onOutroEnd}">
  <slot/>
</section>

<style lang="scss">
  .screen {
    background-color: var(--background);
    display: none;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 77px;

    &.visible {
      display: flex;
    }
  }

  @media (min-width: 1200px) {
    .screen {
      padding-top: var(--navbar-height);
    }
  }
</style>
