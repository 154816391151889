import { camelCase } from 'lodash-es'

// TODO: Create a vite plugin to generate an even more optimized output.
const allIcons = Object.fromEntries([
  ...Object.entries(import.meta.globEagerDefault('./**/*.svg'))
    .map(([path, url]) => [path.slice(2, -4), url]),
  ...Object.entries(import.meta.globEagerDefault('../images/**/*.svg'))
    .map(([path, url]) => [path.slice(10, -4), url]),
  ...Object.entries(import.meta.globEagerDefault('../illustrations/**/*.svg'))
    .map(([path, url]) => [path.slice(3, -4), url]),
])

const iconAliases: Record<string, string> = {
  help: 'phone',
}

// Public: Return the URL for an svg image corresponding to the given name.
export function urlForIcon (name: string) {
  const iconUrl = allIcons[name] || allIcons[toIconName(name)]

  if (!iconUrl) throw new TypeError(`Icon '${name}' is not defined.`)

  return iconUrl
}

export function toIconName (label: string) {
  label = camelCase(label)
  return iconAliases[label] || label
}
